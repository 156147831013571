import React, { useEffect, useRef } from 'react';
import { HeaderTitleDot } from '../../Typography';
import { makeStyles } from '@material-ui/core';
import { useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import { Grid, Container } from '@material-ui/core';
import ApplicationItem from './ApplicationItem';
import { gsap } from "gsap";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '120px',
    marginTop: '60px',

    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  },
}));

const LandingApplication = () => {
  const intl = useIntl();
  const classes = useStyles();
  const containerRef = useRef(null);
  const titleDotRef = useRef(null);
  const applicationItem1Ref = useRef(null);
  const applicationItem2Ref = useRef(null);
  const applicationItem3Ref = useRef(null);

  const data = useStaticQuery(graphql`
    query {
      imgOne: file(relativePath: { eq: "application/img1.jpg" }) {
        childImageSharp {
          fixed(width: 500) {
            src
          }
        }
      },
      imgTwo: file(relativePath: { eq: "application/img2.jpg" }) {
        childImageSharp {
          fixed(width: 500) {
            src
          }
        }
      },
      imgThree: file(relativePath: { eq: "application/img3.jpg" }) {
        childImageSharp {
          fixed(width: 500) {
            src
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (containerRef.current) {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top bottom',
        }
      });

      const gsapAni = {
        y: 200,
        ease: 'power4.out',
        delay: 0.3,
        skewY: 10,
        stagger: {
          amount: 0.4,
        }
      };

      tl
      .from(titleDotRef.current, gsapAni)
      .from(applicationItem1Ref.current, { x: 30, y: 90, opacity: 0, duration: 0.3 })
      .from(applicationItem2Ref.current, { x: 30, y: 90, opacity: 0, duration: 0.3 })
      .from(applicationItem3Ref.current, { x: 30, y: 90, opacity: 0, duration: 0.3 });

    }
  }, []);

  return (
    <Container maxWidth="lg" className={classes.root} ref={containerRef}>
      <HeaderTitleDot ref={titleDotRef} to="/services" title={intl.formatMessage({ id: "application.title" })} showMore />
      <Grid container>
        <ApplicationItem ref={applicationItem1Ref} to="/services" title={intl.formatMessage({ id: "application.desc_one" })} image={data.imgOne.childImageSharp.fixed.src} />
        <ApplicationItem ref={applicationItem2Ref} to="/services" title={intl.formatMessage({ id: "application.desc_two" })} image={data.imgTwo.childImageSharp.fixed.src} />
        <ApplicationItem ref={applicationItem3Ref} to="/services" title={intl.formatMessage({ id: "application.desc_three" })} image={data.imgThree.childImageSharp.fixed.src} />
      </Grid>
    </Container>
  )
}

export default LandingApplication;
