import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    paddingTop: '60px',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
    },

    "& iframe": {
      width: "100%",
    }
  },
}));

const LandingSlideShow = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <iframe src="https://pkhctech.com/slideshow/" title="slideshow" height="768" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
    </div>
  )
}

export default LandingSlideShow;