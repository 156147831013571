import React from 'react';
import { HeaderTitle } from '../../Typography';
import { makeStyles, Grid, Container } from '@material-ui/core';
import { useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import ClientsItem from './ClientsItem';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '60px',
  },
}));

const LandingClients = () => {
  const intl = useIntl();
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query ClientQuery {
      vietinbank: file(relativePath: { eq: "clients/vietinbank.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      hueic: file(relativePath: { eq: "clients/hueic.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      tm: file(relativePath: { eq: "clients/tm.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      systemtechnologies: file(relativePath: { eq: "clients/systemtechnologies.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      yma: file(relativePath: { eq: "clients/ala-youth-media-awards.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      frithue: file(relativePath: { eq: "clients/frit-hue.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      aidinrobotics: file(relativePath: { eq: "clients/AIDIN-ROBOTICS.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      dhhatinh: file(relativePath: { eq: "clients/dhhatinh.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ingkle: file(relativePath: { eq: "clients/ingkle.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  return (
    <Container maxWidth="lg" className={classes.root}>
      <HeaderTitle to="/" title={intl.formatMessage({ id: "our_clients.title" })} />
      <Grid container alignItems="center" justify="center">
        <ClientsItem
          name="Aidin Robotics"
          to="https://aidinrobotics.co.kr"
          image={data.aidinrobotics.childImageSharp.fluid}
        />

        <ClientsItem
          name="TM"
          to="https://www.tm.com.my"
          image={data.tm.childImageSharp.fluid}
        />

        <ClientsItem
          name="System Technologies"
          to="https://www.wirelessnursecall.com"
          image={data.systemtechnologies.childImageSharp.fluid}
        />

        <ClientsItem
          name="YMA"
          to="https://ala.org"
          image={data.yma.childImageSharp.fluid}
        />

        <ClientsItem
          name="Vietinbank"
          to="https://vietinbank.vn"
          image={data.vietinbank.childImageSharp.fluid}
        />

        <ClientsItem
          name="Frit Hue"
          to="https://frithue.com.vn"
          image={data.frithue.childImageSharp.fluid}
        />

        <ClientsItem
          name="Hueic"
          to="https://hueic.edu.vn"
          image={data.hueic.childImageSharp.fluid}
        />

        <ClientsItem
          name="Trường Đại Học Hà Tĩnh"
          to="https://htu.edu.vn"
          image={data.dhhatinh.childImageSharp.fluid}
        />

        <ClientsItem
          name="Ingkle"
          to="http://ingkle.com/"
          image={data.ingkle.childImageSharp.fluid}
        />

      </Grid>
    </Container>
  )
}

export default LandingClients;