import React, { useEffect, useRef } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import { makeStyles } from '@material-ui/core';
import IntroduceBg from '../../../images/introduce_bg.png';
import Request from '../../Request';
import { gsap } from "gsap";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    
    '&::before': {
      background: `url(${IntroduceBg}) no-repeat top center`,
      backgroundSize: '100%',
      content: "''",
      height: '100%',
      opacity: 0.4,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: -1,
    }
  },
  containerTitle: {
    overflow: 'hidden',
  },
  title: {
    fontFamily: 'SVN Aguda',
    fontSize: '53px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '36px',
    },
  },
  containerParagraph: {
    overflow: 'hidden',
  },
  paragraph: {
    fontSize: '24px',
    lineHeight: '50px',
    margin: '0',
    textAlign: 'center',
    textShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
    width: '700px',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1em',
      lineHeight: '2em',
    },
  },
  contact: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
  },
}));

const LandingIntroduce = () => {
  const intl = useIntl();
  const classes = useStyles();
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);

  useEffect(() => {
    if (containerRef) {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top bottom',
        }
      });

      const gsapAni = {
        y: 200,
        ease: 'power4.out',
        delay: 0.3,
        skewY: 10,
        stagger: {
          amount: 0.4,
        }
      };

      tl.from(titleRef.current, gsapAni);
      tl.from(paragraphRef.current, {
        ...gsapAni,
        y: 300,
        duration: 1,
      });

    }
  }, []);

  return (
    <div ref={containerRef} className={classes.root}>
      <div className={classes.containerTitle}>
        <h2 ref={titleRef} className={classes.title}>{intl.formatMessage({ id: "title" })}</h2>
      </div>
      <div className={classes.containerParagraph}>
        <p ref={paragraphRef} className={classes.paragraph}>
          {intl.formatMessage({ id: "description" })}
        </p>
      </div>
      <Request title={intl.formatMessage({ id: "request_title" })} />
    </div>
  )
}

export default LandingIntroduce;
