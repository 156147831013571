import React from 'react';
import { HeaderTitle } from '../../Typography';
import { makeStyles, Container } from '@material-ui/core';
import { useIntl } from "gatsby-plugin-intl";
// import TaxService from './TaxService';
import ListProjects from './ListProjects';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '60px',
  },
  container: {
    flexGrow: 1,
    marginBottom: '60px',
    position: 'relative',
    overflow: 'hidden',
  },
}));

const LandingOurProjects = ({ data }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <HeaderTitle to="/projects" title={intl.formatMessage({ id: "our_projects_title" })} showMore />
      </Container>
      {/* <TaxService /> */}
      <ListProjects data={data} />
    </div>
  )
}

export default LandingOurProjects;