import React, { useRef } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import Img from "gatsby-image";
import { Link } from "gatsby";
import '../../../css/listProject.css';
import HeaderTitleBlog from '../../../components/Typography/HeaderTitleBlog';
import { AniLink } from '../../../components/TransitionLink';
import { FormattedMessage } from "gatsby-plugin-intl";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  viewmore: {
    margin: '40px 0 30px',
    fontSize: '20px',
    textAlign: 'center',

    '& a': {
      border: `1px solid ${theme.palette.text.primary}`,
      padding: '8px 24px',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      transition: 'all .3s ease',

      '&:hover': {
        background: 'linear-gradient(200deg, #2AA7DE, #663399)',
        color: '#fff',
      }
    }
  }
}));

const ListProjects = ({ data }) => {
  const classes = useStyles();
  const containerRef = useRef(null);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container} ref={containerRef}>
        <div className="projectListGrid">

          {
            data.edges.map((document, index) => {
              return (
                <div className={`gridItem${index+1}`} key={index}>
                  <Link className={classes.link} to={`${document.node.frontmatter.path}`}>
                    <Img
                      fluid={document.node.frontmatter.featuredImage.childImageSharp.fluid}
                      alt={ document.node.frontmattertitle }
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </Link>
                  <HeaderTitleBlog to={document.node.frontmatter.path} title={ document.node.frontmatter.title } />
                </div>
              )
            })
          }
        </div>

        <div className={classes.viewmore}>
          <AniLink to="/projects">
            <FormattedMessage id="view_more" />
          </AniLink>
        </div>

      </Container>
    </div>
  )
}

export default ListProjects;

